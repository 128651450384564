















import { Component, Vue, Prop } from "vue-property-decorator";

const ADDRESS_LINE_SPLIT_REGEXP: RegExp = /, \d{5} /;

@Component
export default class ReportCardImageCarousel extends Vue {
  @Prop()
  public address!: string;

  @Prop(Boolean)
  public dense!: boolean;

  get addressLine1() {
    return this.address.split(ADDRESS_LINE_SPLIT_REGEXP)[0];
  }

  get addressLine2() {
    return this.address.substring(
      this.address.split(ADDRESS_LINE_SPLIT_REGEXP)[0].length + 2
    );
  }
}
