
































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Tatbestand, TATBESTÄNDE } from '../../../functions/lib/ParkingPlaces';
import ReportCardTagLine from "./ReportCardTagLine.vue";
import ReportCardImageCarousel from "./ReportCardImageCarousel.vue";

@Component({
  components: {
    ReportCardTagLine,
    ReportCardImageCarousel
  }
})
export default class ReportCardEdit extends Vue {
  @Prop()
  public licensePlate!: string;

  @Prop()
  public date!: number;

  @Prop(Boolean)
  public parking!: boolean;

  @Prop(Boolean)
  public endangering!: boolean;

  @Prop(Boolean)
  public obstruction!: boolean;

  @Prop(Boolean)
  public withIntend!: boolean;

  @Prop()
  public intendReason!: string;

  @Prop()
  public offence!: string;

  @Prop()
  public address!: string;

  @Prop({type: Object, default: () => ({lat: 0, lon: 0})})
  public location!: {
    lat: number,
    lon: number
  }

  @Prop()
  public thumbnail!: string;

  @Prop()
  public imageUrl!: string;

  @Prop({ type: Array, default: () => [] })
  public images!: {
    src: string;
    thumbnail: string;
  }[];

  private parkingPlaces: Tatbestand[] = Object.values(TATBESTÄNDE).sort((a,b) => a.order - b.order);

  private selectedImage: number = 0;

  get localeDate() {
    return new Date(this.date * 1000).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }

  changeEvent(field: string, newValue: unknown) {
    console.log(`change:${field}`, newValue)
    this.$emit(`change:${field}`, newValue);
  }
}
