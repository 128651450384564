





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { TATBESTÄNDE } from '../../../functions/lib/ParkingPlaces';
import ReportCardTagLine from "./ReportCardTagLine.vue";
import ReportCardAddressListItem from "./ReportCardAddressListItem.vue";

@Component({
  components: {
    ReportCardTagLine,
    ReportCardAddressListItem
  }
})
export default class ReportCardSmall extends Vue {
  @Prop(Boolean)
  public showAddress!: boolean;

  @Prop()
  public licensePlate!: string;

  @Prop()
  public date!: number;

  @Prop(Boolean)
  public parking!: boolean;

  @Prop(Boolean)
  public endangering!: boolean;

  @Prop(Boolean)
  public obstruction!: boolean;

  @Prop(Boolean)
  public withIntend!: boolean;

  @Prop()
  public offence!: string;

  @Prop()
  public address!: string;

  @Prop()
  public thumbnail!: string;

  @Prop()
  public imageUrl!: string;

  @Prop(Number)
  public plateCount!: number;

  @Prop({ type: Array, default: () => [] })
  public images!: {
    src: string;
    thumbnail: string;
  }[];

  get localeDate() {
    return new Date(this.date * 1000).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }

  parseTatbestand(tatbestandsNummer: string) {
    return TATBESTÄNDE[tatbestandsNummer]?.title ?? tatbestandsNummer
  }
}
