var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({},'v-card',_vm.$attrs,false),[_c('v-menu',{attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"z-index":"1"},attrs:{"icon":"","absolute":"","right":"","top":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function () {}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-image-plus")])],1),_c('v-list-item-title',[_vm._v("Bild hinzufügen")])],1),_c('v-list-item',{attrs:{"disabled":_vm.images.length < 2},on:{"click":function($event){return _vm.$emit('delete-image', _vm.selectedImage)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-image-off")])],1),_c('v-list-item-title',[_vm._v("Bild löschen")])],1),_c('v-divider',{staticClass:"mx-4 my-2"}),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Anzeige löschen")])],1)],1)],1),_c('report-card-image-carousel',{attrs:{"images":_vm.images},on:{"click-image":function () { return _vm.$emit('click-image'); }},model:{value:(_vm.selectedImage),callback:function ($$v) {_vm.selectedImage=$$v},expression:"selectedImage"}}),_c('v-card-text',[_c('v-text-field',{attrs:{"filled":"","label":"Kennzeichen","prepend-icon":"mdi-car","value":_vm.licensePlate},on:{"change":function (e) { return _vm.changeEvent('license-plate', e); }}}),_c('v-text-field',{staticClass:"mt-n2",attrs:{"filled":"","label":"Datum und Uhrzeit","prepend-icon":"mdi-calendar","value":_vm.localeDate},on:{"change":function (e) { return _vm.changeEvent('date', e); }}}),_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-n2",attrs:{"filled":"","label":"Adresse","prepend-icon":"mdi-map-marker","value":_vm.address},on:{"click:prepend":function (e) { return on.click(e); },"change":function (e) { return _vm.changeEvent('address', e); }}},on))]}}])},[_c('GmapMap',{staticStyle:{"width":"500px","height":"300px"},attrs:{"center":{lat: _vm.location.lat == 0 ? 53.52179749151783 : _vm.location.lat, lng: _vm.location.lon == 0 ? 9.99556059602402 : _vm.location.lon},"zoom":15,"mapTypeId":"roadmap","options":{
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false
        }}},[_c('GmapMarker',{attrs:{"draggable":"","position":{lat: _vm.location.lat == 0 ? 53.52179749151783 : _vm.location.lat, lng: _vm.location.lon == 0 ? 9.99556059602402 : _vm.location.lon}},on:{"dragend":(function (ref) {
            var ref_latLng = ref.latLng;
            var lat = ref_latLng.lat;
            var lng = ref_latLng.lng;

            _vm.changeEvent('location', {lon: lng(), lat: lat()})
          })}})],1)],1),_c('v-divider',{staticClass:"mt-n2 mb-4"}),_c('div',{staticClass:"subtitle-1"},[_vm._v("Typ")]),_c('v-chip-group',{attrs:{"mandatory":"","column":"","active-class":"primary","value":_vm.parking},on:{"change":function (e) { return _vm.changeEvent('parking', e); }}},[_c('v-chip',{attrs:{"value":true,"outlined":""}},[_vm._v("Parken")]),_c('v-chip',{attrs:{"value":false,"outlined":""}},[_vm._v("Halten")])],1),_c('div',{staticClass:"subtitle-1 mt-2"},[_vm._v("Ort")]),_c('v-chip-group',{attrs:{"mandatory":"","column":"","active-class":"primary","value":_vm.offence},on:{"change":function (e) { return _vm.changeEvent('offence', e); }}},_vm._l((_vm.parkingPlaces),function(place){return _c('v-chip',{key:place.tbnr,attrs:{"outlined":"","value":place.tbnr}},[_vm._v(_vm._s(place.title))])}),1),_c('v-divider',{staticClass:"mt-2"}),_c('v-switch',{staticClass:"mb-n6",attrs:{"input-value":_vm.obstruction,"inset":"","label":"Behinderung"},on:{"change":function (e) { return _vm.changeEvent('obstruction', e); }}}),_c('v-switch',{staticClass:"mb-n6",attrs:{"input-value":_vm.endangering,"inset":"","label":"Gefährdung"},on:{"change":function (e) { return _vm.changeEvent('endangering', e); }}}),(_vm.withIntend)?_c('v-divider',{staticClass:"mb-4 mt-4"}):_vm._e(),_c('v-switch',{attrs:{"input-value":_vm.withIntend,"inset":"","label":"Vorsatz"},on:{"change":function (e) { return _vm.changeEvent('with-intend', e); }}}),(_vm.withIntend)?_c('v-textarea',{staticClass:"mt-n2",attrs:{"value":_vm.intendReason,"filled":"","rows":"1","auto-grow":"","label":"Begründung","dense":"","append-icon":"mdi-pencil"},on:{"change":function (e) { return _vm.changeEvent('intend-reason', e); }}}):_vm._e()],1),_c('v-card-actions',{staticClass:"mt-n6"},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.$emit('send')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-send")]),_vm._v(" Anzeige Verschicken")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }